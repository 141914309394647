/* ----------------------------------------------------------------
    FOOTER
-----------------------------------------------------------------*/
#footer {
    display: block;
    position: relative;
    background-color: $footer-background;
    font-size: 14px;
	line-height: 32px;

    font-weight: 400;
    font-family: $font-headings;
    p {
        font-family: $font-headings;

    }
    a:not(.btn) {

        font-weight: 400;
    }
    h1,h2,h3,h4,h5,h6 {

    }
    
    .footer-content {
        padding: 60px 0 40px 0;
    }

    .widget .widget-title,
    .widget h4 {
        font-family: $font-headings;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 600;
        ul {
            li {
                a {
                    font-family: $font-headings;
                    font-size: 15px;
                    line-height: 32px;
                    color: #999; 
                }
            }
        }
    }

    .copyright-content {
        min-height: 80px;
        padding: 30px 0;
        background-color: darken($footer-background, 3%);
        font-size: 13px;
    }


    //chkd
    .logo {
        margin-bottom: 54px;
        margin-top: 12px;
        max-width: 80px;
    }
    //chkd
    .footer-logo {
        border-right: 1px solid #333;
        margin-bottom: 20px;
        margin-right: 18px;
        padding-right: 16px;
    }


    // Dark Version 
    &.inverted {
        background-color: #181818;

        p {
            font-family: $font-headings;
            color: #999; 
        }
        a:not(.btn) {
            color: #999;    
            font-weight: 400;
        }
        h1,h2,h3,h4,h5,h6 {
            color: #fff;  
        }
        
        .widget .widget-title,
        .widget h4 {
            color: #fff;
        }
    
        .copyright-content {
            background-color: #1E1E1E;
        }
    
       

     /*    .post-thumbnail-list .post-thumbnail-entry {
            border-bottom: 1px solid #222;
        }

        .social-icons-colored a {
            color: $color-white;
        } */
    }
}


// Social Icons
/*
#footer {

    background-color: $background-light;
    border-top: 1px solid #e5e5e5;

    span {
        color: #898989;
    }

    .widget ul {
        margin-bottom: 0;
    }

    .widget-tags .tags a {
        border: 1px solid rgba(255, 255, 255, 0.2);
    }


    .footer-content {
        padding: 60px 0 40px 0;
    }


    .copyright-content {
        background-color: #efefef;
        border-top: 1px solid #e5e5e5;



        border-top: 1px solid #ddd;
        min-height: 80px;
        padding: 30px 0;

    }

    strong {
        color: #5a5a5a;
    }


    .post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
        font-size: 13px;
        font-weight: 500;
    }

    .social-icons {
        &.center {
            float: none;
            display: inline-block;
        }

        &:not(.social-icons-colored) li a:hover {
            background-color: transparent;
        }
    }

    
}
*/