/***********************************************************************
**														              **
**         POLO Html5 Template		   								  **
**         Author: INSPIRO MEDIA								      **
**		   URL: http://www.inspiro-media.com						  **
**														              **
************************************************************************/


/*  Mixins */
@import "mixins";

/*  Base */
@import "base/include";

/*  Components */
@import "components/include";

/*  Elements */
@import "elements/include";






                                                                                                 
