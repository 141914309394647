/***********************************************************************
**														              **
**         POLO Html5 Template		   								  **
**         Author: INSPIRO MEDIA								      **
**		   URL: http://www.inspiro-media.com						  **
**														              **
************************************************************************/

/*  Content & Layout */ 
@import "body/layout";
@import "body/page-title";
@import "body/content";
@import "body/grid";

/*  Header */ 
@import "header/topbar";
@import "header/header";

/*  Typography */ 
//@import "fonts";
@import "typography/typography";

/*  Sliders */ 
@import "sliders/sliders";

/*  Portfolio */ 
@import "portfolio/portfolio"; 

/*  Blog & comments */ 
@import "blog/blog";
@import "comments/comments"; 

/*  Shop */ 
@import "shop/shop";

/*  Forum */ 
@import "forum/forum";

/*  Helpers */
@import "helpers/helpers";

/*  Footer */
@import "footer/footer";

/*  Widgets */
@import "widgets/widgets";

/*  Preloaders  */
@import "preloaders/preloaders";

/*  Extras */
@import "extras/extras";

