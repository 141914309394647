


.forum {
  border-radius: 4px;
  padding: 20px 26px;
  background-color: #fff;
  .fa {
    width: 1em;
    text-align: center;
  }
  table th {
    width: 6em;
    width: 14em;
  }
  thead tr {
    > th {
      background-color: $color-theme;
      color: #fff;
      border-bottom: 0;
    }
    &:first-child th:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child th:last-child {
      border-top-right-radius: 4px;
    }
    h3 {
      color: #fff;
      margin-bottom: 0px;
      font-size: 22px;
    }
  }
  h4 > small {
    display: block;
  }
}