// Css Utility helpers
/*.d-block {
    display: block;
    clear: both;
}*/


.hidden {
    display: none;
}

.visible {
    opacity: 1; 
}

.text-center {
    text-align: center !important;
}


.text-left.nav-tabs .nav-item {
    text-align: left !important;
}

.text-right.nav-tabs .nav-item {
    text-align: right !important;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

.center {
    float: none !important;
    margin: 0 auto;
}

.block {
    display: block;
}

.align-center {
    display: inline-flex;
    text-align: center;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.text-middle {
    display: table-cell;
    vertical-align: middle;
    margin: auto;
    z-index: 3;
    position: relative;
}

.text-bottom {
    display: table-cell;
    vertical-align: bottom;
    margin: auto;
    z-index: 3;
    position: relative;
}



.bg-light {
    background-color: $background-light;
}

.text-background-light {
    background-color: #fff;
    padding: 0 4px;
}

.text-background-dark {
    background-color: #444;
    padding: 0 4px;
}

.text-shadow-light {
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.53);
}

.text-shadow-dark {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.53);
}


.background-primary {
    background-color: $background-primary !important;
} 

.background-secondary {
    background-color: $background-secondary !important;
}
.background-success {
    background-color: $background-success !important;
}
.background-info {
    background-color: $background-info !important;
}
.background-warning {
    background-color: $background-warning !important;
}
.background-danger {
    background-color: $background-danger !important;
}

.background-white {
    background-color: $background-white;
}
.background-light {
    background-color: $background-light;
}

.background-grey {
    background-color: $background-grey;
}

.background-dark {
    background-color: $background-dark;
}

.background-black {
    background-color: $background-black;
}

.background-pattern {
    background-image: url(/images/overlay-pattern/gplay.png);
}

.background-pattern-1 {
    background-image: url(/images/overlay-pattern/3px-tile.png);
}

.background-pattern-2 {
    background-image: url(/images/overlay-pattern/asfalt-dark.png);
}

.background-pattern-3 {
    background-image: url(/images/overlay-pattern/axiom-pattern.png);
}

// Box shadow
.shadow {
    box-shadow: 0 4px 16px #e2e2e2;
}

.post-shadow {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.shadow-bottom {
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);
}

.shadow-inside {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

.shadow-inside-top {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.08);
}

.shadow-inside-bottom {
    box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.08);
}

// Predefined text helpers

.text-xs,
.text-xs span {
    font-size: 30px;
    line-height: 30px;
}


.text-medium-light {
    font-size: 50px !important;
    font-weight: 100;
    line-height: 1.1;
    font-family: "Open Sans";
}

.text-medium {
    font-size: 50px !important;
    font-weight: 800;
    line-height: 1.1;
    margin-bottom: 20px;
}

body.breakpoint-sm .text-medium,
body.breakpoint-sm .text-medium-light {
    font-size: 40px !important;
}

body.breakpoint-xs .text-medium,
body.breakpoint-xs .text-medium-light {
    font-size: 30px !important;
}

.text-lg-light,
.text-lg-light span,
.text-lg-light span span {
    font-size: 90px !important;
    line-height: 100px !important;
    font-weight: 120 !important;
}

.text-lg,
.text-lg span,
.text-lg span span {
    font-size: 90px;
    line-height: 100px;
    font-weight: 800;
}

.text-xl,
.text-xl span {
    font-size: 120px;
    font-weight: 800;
    line-height: 120px;
}
.text-lg-x2,
.text-lg-x2 span {
    color: #ffffff;
    font-size: 120px;
    line-height: 140px;
    font-weight: 100;
    margin-bottom: 10px;
}

.text-lg-x3,
.text-lg-x3 span { 
    color: #ffffff;
    font-size: 230px;
    line-height: 250px;
    font-weight: 100;
}

body.breakpoint-md,
body.breakpoint-sm,
body.breakpoint-xs {
    .text-lg,
    .text-lg span,
    .text-lg span span,
    .text-lg-light,
    .text-lg-light span,
    .text-lg-light span span,
    .text-xl,
    .text-xl span {
        font-size: 70px;
line-height: 70px;
    }
}
.text-small {
    font-size: 16px;
    font-weight: 800;
    line-height: 1.1;
}




.text-light,
.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light .lead,
.text-light p,
.text-light a:not(.btn),
.text-light a:not(.btn):hover:not(.btn):not(.btn-light),
.text-light i,
.text-light li,
.text-light label,
.text-light div:not(.alert),
.text-light span:not(.btn-label) {
    color: #fff !important;
}

.text-dark,
.text-dark h1,
.text-dark h2,
.text-dark h3,
.text-dark h4,
.text-dark h5,
.text-dark h6,
.text-dark p,
.text-dark .lead,
.text-dark a:not(.button),
.text-dark a:not(.btn):hover,
.text-dark i,
.text-dark li,
.text-dark label,
.text-dark div:not(.alert),
.text-dark span:not(.btn-label) {
    color: #111 !important;
}

.text-grey,
.text-grey h1,
.text-grey h2,
.text-grey h3,
.text-grey h4,
.text-grey h5,
.text-grey h6,
.text-grey .lead,
.text-grey p,
.text-grey a:not(.button),
.text-grey a:not(.btn-light):hover,
.text-grey i,
.text-grey li,
.text-grey label,
.text-grey div:not(.alert),
.text-grey span:not(.btn-label) {
    color: #ccc;
}

.text-white {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    i {
        color: #fff !important;
    }
}

.with-errors li {
    color: #981a1a !important;
}

.text-bold {
    font-weight: bold !important;
}

.text-azure {
    color: #0095C8 !important;
}

.text-orange {
    color: #FF6600 !important;
}

.text-theme {
    color: $color-theme !important;
}

.text-blue {
    color: #5F8295 !important;
}

.text-pink {
    color: #DD5A82 !important;
}

.text-purple {
    color: #DD5A82 !important;
}

.text-bricky {
    color: #894550 !important;
}

.text-yellow {
    color: #FFB848 !important;
}

.text-red {
    color: #CA0027 !important;
}

/*font weight*/

.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

// Predefined margin & padding classes 
.border-box {
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 30px;
}

.border-left {
    border-left: 1px solid #eee;
}

.border-top {
    border-top: 1px solid #eee;
}

.border-right {
    border-width: 0 1px 0 0;
    border-color: #eee;
    border-style: solid;
}

.border-bottom {
    border-bottom: 1px solid #eee;
}

.border-dotted {
    border-style: dotted;
}

.slider-size-half {
    max-height: 560px;
}

.no-margin {
    margin: 0px !important
}

.m-b-0 {
    margin-bottom: 0px !important
}

.m-b-5 {
    margin-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px !important
}

.m-b-15 {
    margin-bottom: 15px !important
}

.m-b-20 {
    margin-bottom: 20px !important
}

.m-b-25 {
    margin-bottom: 25px !important
}

.m-b-30 {
    margin-bottom: 30px !important
}

.m-b-35 {
    margin-bottom: 35px !important
}

.m-b-40 {
    margin-bottom: 40px !important
}

.m-b-50 {
    margin-bottom: 50px !important
}

.m-b-60 {
    margin-bottom: 60px !important
}

.m-b-70 {
    margin-bottom: 70px !important
}

.m-b-80 {
    margin-bottom: 80px !important
}

.m-b-90 {
    margin-bottom: 90px !important
}

.m-b-100 {
    margin-bottom: 100px !important
}

.m-b-150 {
    margin-bottom: 150px !important
}

.m-b-200 {
    margin-bottom: 200px !important
}

.m-t-0 {
    margin-top: 0px !important
}

.m-t-5 {
    margin-top: 5px !important
}

.m-t-10 {
    margin-top: 10px !important
}

.m-t-15 {
    margin-top: 15px !important
}

.m-t-20 {
    margin-top: 20px !important
}

.m-t-25 {
    margin-top: 25px !important
}

.m-t-30 {
    margin-top: 30px !important
}

.m-t-35 {
    margin-top: 35px !important
}

.m-t-40 {
    margin-top: 40px !important
}

.m-t-50 {
    margin-top: 50px !important
}

.m-t-60 {
    margin-top: 60px !important
}

.m-t-70 {
    margin-top: 70px !important
}

.m-t-80 {
    margin-top: 80px !important
}

.m-t-90 {
    margin-top: 90px !important
}

.m-t-100 {
    margin-top: 100px !important
}

.m-l-0 {
    margin-left: 0px !important;
}

.m-l-5 {
    margin-left: 5px !important
}

.m-l-10 {
    margin-left: 10px !important
}

.m-l-15 {
    margin-left: 15px !important
}

.m-l-20 {
    margin-left: 20px !important
}

.m-l-25 {
    margin-left: 25px !important
}

.m-l-30 {
    margin-left: 30px !important
}

.m-l-35 {
    margin-left: 35px !important
}

.m-l-40 {
    margin-left: 40px !important
}

.m-l-50 {
    margin-left: 50px !important
}

.m-l-60 {
    margin-left: 60px !important
}

.m-l-70 {
    margin-left: 70px !important
}

.m-l-80 {
    margin-left: 80px !important
}

.m-l-90 {
    margin-left: 90px !important
}

.m-l-100 {
    margin-left: 100px !important
}

.m-r-0 {
    margin-right: 0px !important
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-r-10 {
    margin-right: 10px !important
}

.m-r-15 {
    margin-right: 15px !important
}

.m-r-20 {
    margin-right: 20px !important
}

.m-r-25 {
    margin-right: 25px !important
}

.m-r-30 {
    margin-right: 30px !important
}

.m-r-35 {
    margin-right: 35px !important
}

.m-r-40 {
    margin-right: 40px !important
}

.m-r-50 {
    margin-right: 50px !important
}

.m-r-60 {
    margin-right: 60px !important
}

.m-r-70 {
    margin-right: 70px !important
}

.m-r-80 {
    margin-right: 80px !important
}

.m-r-90 {
    margin-right: 90px !important
}

.m-r-100 {
    margin-right: 100px !important
}

.m-0 {
    margin: 0px !important;
}

.m-5 {
    margin: 5px !important
}

.m-10 {
    margin: 10px !important
}

.m-15 {
    margin: 15px !important
}

.m-20 {
    margin: 20px !important
}

.m-25 {
    margin: 25px !important
}

.m-30 {
    margin: 30px !important
}

.m-35 {
    margin: 35px !important
}

.m-40 {
    margin: 40px !important
}

.m-50 {
    margin: 50px !important
}

.m-60 {
    margin: 60px !important
}

.m-70 {
    margin: 70px !important
}

.m-80 {
    margin: 80px !important
}

.m-90 {
    margin: 90px !important
}

.m-100 {
    margin: 100px !important
}

/*Margins*/

.col-no-margin {
    padding: 0 !important;
    [class^="col-"] {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.row.col-no-margin {
    margin: 0 !important;
    padding: 0 !important;
}

.row.col-mdall-margins [class^="col-"] {
    margin: 0 0 20px 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.p-0 {
    padding: 0px !important;
}

.p-5 {
    padding: 5px !important
}

.p-10 {
    padding: 10px !important
}

.p-15 {
    padding: 15px !important
}

.p-20 {
    padding: 20px !important
}

.p-25 {
    padding: 25px !important
}

.p-30 {
    padding: 30px !important
}

.p-35 {
    padding: 35px !important
}

.p-40 {
    padding: 40px !important
}

.p-50 {
    padding: 50px !important
}

.p-60 {
    padding: 60px !important
}

.p-70 {
    padding: 70px !important
}

.p-80 {
    padding: 80px !important
}

.p-90 {
    padding: 90px !important
}

.p-100 {
    padding: 100px !important
}

.p-150 {
    padding: 150px !important
}

.p-200 {
    padding: 200px !important
}

.p-b-0 {
    padding-bottom: 0px !important
}

.p-b-5 {
    padding-bottom: 5px !important
}

.p-b-10 {
    padding-bottom: 10px !important
}

.p-b-15 {
    padding-bottom: 15px !important
}

.p-b-20 {
    padding-bottom: 20px !important
}

.p-b-25 {
    padding-bottom: 25px !important
}

.p-b-30 {
    padding-bottom: 30px !important
}

.p-b-35 {
    padding-bottom: 35px !important
}

.p-b-40 {
    padding-bottom: 40px !important
}

.p-b-50 {
    padding-bottom: 50px !important
}

.p-b-60 {
    padding-bottom: 60px !important
}

.p-b-70 {
    padding-bottom: 70px !important
}

.p-b-80 {
    padding-bottom: 80px !important
}

.p-b-90 {
    padding-bottom: 90px !important
}

.p-b-100 {
    padding-bottom: 100px !important
}

.p-b-110 {
    padding-bottom: 110px !important
}

.p-b-120 {
    padding-bottom: 120px !important
}

.p-b-130 {
    padding-bottom: 130px !important
}

.p-b-140 {
    padding-bottom: 140px !important
}

.p-b-150 {
    padding-bottom: 150px !important
}

.p-b-200 {
    padding-bottom: 200px !important
}

.p-t-0 {
    padding-top: 0px !important
}

.p-t-5 {
    padding-top: 5px !important
}

.p-t-10 {
    padding-top: 10px !important
}

.p-t-15 {
    padding-top: 15px !important
}

.p-t-20 {
    padding-top: 20px !important
}

.p-t-25 {
    padding-top: 25px !important
}

.p-t-30 {
    padding-top: 30px !important
}

.p-t-35 {
    padding-top: 35px !important
}

.p-t-40 {
    padding-top: 40px !important
}

.p-t-50 {
    padding-top: 50px !important
}

.p-t-60 {
    padding-top: 60px !important
}

.p-t-70 {
    padding-top: 70px !important
}

.p-t-80 {
    padding-top: 80px !important
}

.p-t-90 {
    padding-top: 90px !important
}

.p-t-100 {
    padding-top: 100px !important
}

.p-t-110 {
    padding-top: 110px !important
}

.p-t-120 {
    padding-top: 120px !important
}

.p-t-130 {
    padding-top: 130px !important
}

.p-t-140 {
    padding-top: 140px !important
}

.p-t-150 {
    padding-top: 150px !important
}

.p-t-200 {
    padding-top: 200px !important
}

.p-r-0 {
    padding-right: 0px !important
}

.p-r-5 {
    padding-right: 5px !important
}

.p-r-10 {
    padding-right: 10px !important
}

.p-r-15 {
    padding-right: 15px !important
}

.p-r-20 {
    padding-right: 20px !important
}

.p-r-25 {
    padding-right: 25px !important
}

.p-r-30 {
    padding-right: 30px !important
}

.p-r-35 {
    padding-right: 35px !important
}

.p-r-40 {
    padding-right: 40px !important
}

.p-r-50 {
    padding-right: 50px !important
}

.p-r-60 {
    padding-right: 60px !important
}

.p-r-70 {
    padding-right: 70px !important
}

.p-r-80 {
    padding-right: 80px !important
}

.p-r-90 {
    padding-right: 90px !important
}

.p-r-100 {
    padding-right: 100px !important
}

.p-l-0 {
    padding-left: 0px !important
}

.p-l-5 {
    padding-left: 5px !important
}

.p-l-10 {
    padding-left: 10px !important
}

.p-l-15 {
    padding-left: 15px !important
}

.p-l-20 {
    padding-left: 20px !important
}

.p-l-25 {
    padding-left: 25px !important
}

.p-l-30 {
    padding-left: 30px !important
}

.p-l-35 {
    padding-left: 35px !important
}

.p-l-40 {
    padding-left: 40px !important
}

.p-l-50 {
    padding-left: 50px !important
}

.p-l-60 {
    padding-left: 60px !important
}

.p-l-70 {
    padding-left: 70px !important
}

.p-l-80 {
    padding-left: 80px !important
}

.p-l-90 {
    padding-left: 90px !important
}

.p-l-100 {
    padding-left: 100px !important
}

// border radius
.b-r-2 {
    border-radius: 2px !important;
}

.b-r-3 {
    border-radius: 3px !important;
}

.b-r-4 {
    border-radius: 4px !important;
}

.b-r-5 {
    border-radius: 5px !important;
}

.b-r-6 {
    border-radius: 6px !important;
}

.b-r-7 {
    border-radius: 7px !important;
}

.b-r-8 {
    border-radius: 8px !important;
}

.b-r-9 {
    border-radius: 9px !important;
}

.b-r-10 {
    border-radius: 10px !important;
}

.b-radius {
    border-radius: 50% !important;
}

// floats
.float-right {
    float: right !important;
}

.float-left {
    float: left !important;
}

.float-none {
    float: none !important;
}

.fullwidth {
    width: 100% !important;
}

/*Line height*/

.lh80 {
    line-height: 80px !important;
}



.line {
    clear: both;
    position: relative;
    width: 100%;
    margin: 50px 0;
    border-top: 1px solid #e3e3e3;
}

/*Resposnive settings*/

@media (max-width:991px) {
    .text-lg,
    .text-lg span {
        font-size: 60px !important;
        line-height: 60px !important;
    }
    .text-lg-x2,
    .text-lg-x2 span,
    .text-lg-x3,
    .text-lg-x3 span {
        font-size: 70px !important;
        line-height: 70px !important;
    }
}

@media (max-width:768px) {
    .text-lg,
    .text-lg span {
        font-size: 30px !important;
        line-height: 30px !important;
    }
    .text-lg-x2,
    .text-lg-x2 span,
    .text-lg-x3,
    .text-lg-x3 span {
        font-size: 30px !important;
        line-height: 30px !important;
    }
}




.space {
    width: 100%;
    display: block;
    clear: both;
    height: 80px;
    position: relative;
}