
/* ----------------------------------------------------------------
    Maps
-----------------------------------------------------------------*/
.map {
  width: 100%;
  min-height: 370px;
  height: auto;
}

