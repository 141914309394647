// Widgets 
// Widgets 
.widget {
    margin-bottom: 30px;
    position: relative;

    .widget-title,
    >h4 {
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 24px;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    &:after,
    &:before {
        clear: both;
        content: " ";
        display: table;
    }

    iframe {
        width: 100%;
        height: auto;
    }
}

.sidebar {
    .widget {
        border-bottom: 1px solid #eee;
        margin-bottom: 30px;
        padding-bottom: 30px;

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        #testimonials .testimonial-item>img {
            border-radius: 50%;
            display: block;
            float: none;
            height: 50px !important;
            margin: 0 auto 20px;
            width: 50px !important;
        }
    }

    /*    .widget-title::after {
        border-top: 4px solid #444;
        content: "";
        display: block;
        margin-top: 14px;
        width: 60px;
    }*/
}

// Widget tags

.tags a {
    border: 2px solid #f3f3f3;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    outline: none;
    padding: 5px 14px;
    margin: 0 2px 5px 0;
    background-color: transparent;
    border-color: #ebebeb;
    color: #4c5667;
    display: inline-block;

    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: $color-theme;
        border-color: $color-theme;
        color: #fff;
    }

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

// Archive widgets
.widget-archive ul {
    padding-left: 0;
}

body {
    &.breakpoint-md .sidebar .widget {
        float: left;
        width: 330px;
    }

    &.breakpoint-sm .sidebar .widget {
        width: 100%;
    }

    &.breakpoint-md .sidebar .widget:nth-child(odd),
    &.breakpoint-sm .sidebar .widget:nth-child(odd) {
        margin-right: 30px;
    }
}

.flickr-widget,
.widget-instagram {
    opacity: 0;
    min-height: 225px;
    transition: opacity .3s ease;
    margin-bottom: 20px;

    &.widget-instagram-loaded,
    &.flickr-widget-loaded {
        opacity: 1;
    }

    a {
        img {
            width: 25%;
            height: auto;
            padding: 3px;
            border-radius: 6px;
        }
    }

    .col-lg-3>a img {
        padding: 2px;
        width: 25%;
    }

    .col-lg-4>a>img {
        width: 25%;
    }
}

body.breakpoint-sm .col-3 {
    .flickr-widget img .widget-instagram img {
        width: 16.666%;
    }
}

// social widget 
.widget .footer-widget .social-icon {
    background-color: rgba(0, 0, 0, 0.2);
}



// Newsletter widgets 
.widget.widget-newsletter {
    button {
        margin-left: -1px;
    }

    button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .btn {
        text-transform: none;
    }

    .form-control {
        height: 40px;
    }

    .btn {
        font-size: 12px;
        font-weight: 600;
        height: 40px;
        padding: 8px 16px;
    }

    label.error {
        color: #e42c3e;
        display: none !important;
        font-weight: 400;
        margin-top: 5px;
    }
}

.background-dark .widget.widget-newsletter {
    .input-group-prepend {
        background-color: $color-white;
        color: $color-white;
    }

    .form-transparent-fields .form-control {
        border-left: 0;
        padding-left: 0;
    }
}

.form-control.error,
.sm-form-control.error {
    border-color: #e42c3e;
}

.widget-newsletter small {
    display: block;
}

// search widegt
.widget.widget-search {
    button {
        margin-left: -1px;
    }

    button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .btn {
        text-transform: none;
    }

    .btn {
        font-size: 12px;
        font-weight: 600;
        height: 40px;
        padding: 8px 16px;
    }
}

.background-dark .widget.widget-search .input-group-prepend {
    background-color: rgba(0, 0, 0, 0.35);
    border-color: rgba(0, 0, 0, 0.25);
    color: #999;
}

// Widget
#widget-contact-form {
    clear: left;
}

// Twitter
.widget-tweeter,
.widget-twitter {
    min-height: 248px;
}

.widget-tweeter li a,
.widget-twitter li a {
    color: $color-theme;
}

.widget-tweeter:not([data-avatar="true"]) li {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.widget-tweeter:not([data-avatar="true"]) li:before,
.widget-twitter:not([data-avatar="true"]) li:before {
    margin-left: -22px;
    position: absolute;
    font-family: "Font Awesome 5 Brands";
    content: "\f099";
}

.widget-tweeter[data-avatar="true"] li img,
.widget-twitter[data-avatar="true"] li img {
    border-radius: 50%;
    left: -4px;
    position: absolute;
    width: 26px;
    z-index: 3;
}

.widget-tweeter[data-avatar="true"] li,
.widget-twitter[data-avatar="true"] li {
    padding-left: 10px;
}

.widget-tweeter li,
.widget-twitter li {
    margin-bottom: 18px;
}

.widget-tweeter li:last-child,
.widget-twitter li:last-child {
    margin-bottom: 0px;
}

.widget-tweeter ul,
.widget-twitter ul {
    list-style: none;
    padding-left: 22px;
    margin-bottom: 0;
}

.widget-tweeter small,
.widget-twitter small {
    display: block;
    color: #999 !important;
    width: 100%;
}

.widget-newsletter .input-group-prepend i {
    color: #555 !important;
}


/*Widget: Contact us*/

.widget-contact-us ul {}


.widget-gallery a>img {
    float: left;
    height: auto;
    width: 33.3333333%;
    padding: 3px;
}

.widget-myaccount {
    min-width: 220px;
    .avatar {
        text-align: center;
        margin-bottom: 20px;
        img {
            width: 80px;
            border-radius: 100%;
            margin-bottom: 6px;
        }

        span {
            display: block;
            font-size: 18px;
            font-weight: 700;
        }
    }

    
        ul > li,
            >li {
                display: block;
                &:first-child {
                    label {
                        margin-top: 0;
                    }
                }

                label {
                    color: $color-light;
                    font-size: 11px;
                    text-transform: uppercase;
                    margin-top: 14px;
                    margin-bottom: 0;
                }

                a {
                    line-height: 26px;
                    white-space: nowrap;
                    display: block;
                    padding: 2px 8px;

                    i {
                        margin-right: 6px;
                    }
                }

            }
}
//widget My cart
.widget-mycart {

    min-width: 246px;
    width: 100%;
    position: relative;

    .cart-item {
        margin-top: 8px;
        display: inline-table;
        width: 100%;

        .cart-image {
            width: 50px;
            height: 50px;
            float: left;
            overflow: hidden;
            border-radius: 4px;

            img {
                width: 100%;
            }
        }

        .cart-product-meta {
            float: left;
            padding-left: 20px;
            >a {
                font-weight: 700;
                display: block;
            }
            span {
                opacity: .7;
            }
        }

        .cart-item-remove {
            width: 20px;
            float: right;
            font-size: 12px;
             i {
            color: $color-body !important;
        }
        }
    }

    .cart-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 4px;
        margin: 10px 0;
        .cart-total-labels {
            text-align: left;

            span {
                margin-bottom: 2px;
                display: block;
                font-weight: 500;

                strong {
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
        .cart-total-prices {
            text-align: right;

            span {
                margin-bottom: 2px;
                display: block;
                font-weight: 500;

                strong {
                    font-weight: 700;
                    font-size: 16px;
                }
            }
        }
    }

    .cart-buttons .btn{
        margin-bottom: 0;
    }
}

//widget My cart
.widget-notification {

    min-width: 246px;
    width: 100%;
    position: relative;


    .notification-item {
        display: flex;
        align-items: flex-start;
        margin-top: 12px;
        width: 100%;
        position: relative;

        &.notification-new::before {
            content: " ";
            background-color: $background-danger;
            width: 10px;
            height: 10px;
            position: absolute;
            border-radius: 50%;
            right: 0;
            top: 5px;
        }

        .notification-image {
            width: 36px;
            height: 36px;
            border-radius: 100%;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .notification-meta {
            flex: 1px;
            padding-left: 12px;

            >a {
                font-weight: 600;
                display: block;
            }

            span {
                opacity: .6;
                font-size: 11px;
            }
        }
    }
}

/*Widget Tabs*/

.widget .tabs-content {
    margin-bottom: 0;
    padding-bottom: 0;
}

.sidebar .widget .tabs li>a {
    /*font-size: 11px;*/
    padding: 10px;
}

.sidebar .widget .tabs .tabs-navigation li {
    float: left;
    display: inherit;
}

.widget .post-thumbnail-content a {
    font-size: 13px;
    line-height: 20px;
    display: block;
}

.sidebar .widget .post-thumbnail-list .post-thumbnail-entry>img {
    height: 48px;
    margin-right: 10px;
    width: 64px;
}

.sidebar .widget .post-thumbnail-list .post-thumbnail-entry img+.post-thumbnail-content {
    padding-left: 72px;
}

.sidebar .widget .post-thumbnail-entry .post-thumbnail-content .post-date,
.post-thumbnail-entry .post-thumbnail-content .post-category {
    font-size: 11px;
}

.sidebar .widget .post-thumbnail-entry:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

@media (max-width: 991px) {
    .sidebar {
        margin-bottom: 40px;
    }
}


/*Dark*/

.dark {
    .widget {

        .widget-title,
        >h4 {
            color: $color-white;
        }
    }
}