/***********************************************************************
**														              **
**         POLO Html5 Template		   								  **
**         Author: INSPIRO MEDIA								      **
**		   URL: http://www.inspiro-media.com						  **
**														              **
************************************************************************/

// Template Elements
@import "accordion-toggles";
@import "alerts";
@import "buttons";
@import "cards";
@import "headings";
@import "tabs";
@import "social-icons";
@import "icons";
@import "icon-boxes";
@import "carousel";
@import "counters";
@import "countdown-timers";
@import "dropcat-highlight"; 
@import "team-members";
@import "progress-bars";
@import "pie-charts";
@import "horizontal-rules";
@import "seperator";
@import "spinners";
@import "blockquotes";
@import "forms";
@import "labels-badgets";
@import "light-box";
@import "lists";
@import "maps";
@import "pagination-pager";
@import "breadcrumbs";
@import "nav-navbar";
@import "call-to-action";
@import "tooltop-popover";
@import "modal";
@import "client-logos";
@import "pricing-tables";
@import "testimonials";
@import "timeline";
@import "yt-player";
@import "elements-extras";
@import "parallax";
