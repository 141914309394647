 
.input-daterange .input-group-addon {
  min-width: 44px;
}

.datepicker {
  width: 260px;
  padding: 10px !important;
  table {
    width: 100%;
  }
  
  &.datepicker-dropdown {
      box-shadow: 0px 0px 15px 1px rgba(94, 94, 94, 0.15);
  }
  thead th,
  td, th {
    border-top: none;
    border-bottom: none;
  }
  td, th {
    width: 34px !important;
    height: 32px !important;
    border-radius: 3px !important;
  }
 
  tbody tr > td {
 
    &.day {
      &:hover {
        background: #ebedf2;
      }
      &.old {
        color: #9c9c9c;
      }
      &.new {
        color: #9c9c9c;
      }
      &.today.active,
      &.active, &.selected {
        background: $color-theme !important;
        color: $color-white;
        &:hover {
          background: $color-theme;
          color: $color-white;
        }
      }
      &.today{
        opacity: .8;
      }
       
      &.range {
        background: #f4f5f8; 
        &.today {
          background: $color-theme;
          opacity: .4;
        }
      }
    }
    span.month {
      color: #575962;
      &.focused, &:hover {
        background: #ebedf2;
      }
      &.active {
        background:$color-theme;
        color: #fff;
        &:hover {
          background:$color-theme;
          color: #fff;
        } 
      }
    }
  }
  tfoot tr > th {
    &.clear:hover, &.today:hover {
      background: #ebedf2;
    }
  }
  &.datepicker-inline {
    border: 1px solid #f4f5f8;
  }

}


.timepicker-picker {
  a.btn {
    background: transparent !important;
    border: none !important;
      span {
        &::before {
          color: #000;
        }
      }
    &:hover {
      background: #ebedf2;
    }
  }
  .separator:before,
  .separator:after {
    border: none !important;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
  padding: 0px;
  box-shadow: 0px 0px 15px 1px rgba(94, 94, 94, 0.15);
  z-index: 1110 !important;
}

.timepicker,
.datetimepicker {
 width: 260px;
padding: 10px;
}


.datetimepicker {



  &.datetimepicker-orient-top {
    margin-top: 8px;
  }
  table {
    width: 100%;
  }
  td, th {
    width: 34px !important;
    height: 32px !important;
    border-radius: 3px !important;

  }
  thead th {
    &.next > span, &.prev > span {
      display: inline-block;
      font-family: inspiro-icons !important;
      font-size: 16px;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
    }
    &.next > span:before {
      content: "\e95a";
    }
    &.prev > span:before {
      content: "\e959";
    }
  }
  tbody tr > td.day.today {
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      border: solid transparent;
      border-width: 0 0 7px 7px;
      border-bottom-color: #fff;
      border-top-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
  }
  tfoot tr > th {
    &.clear, &.today {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      font-weight: 500;
    }
  }
}

.input-daterange .input-group-addon {
  min-width: 44px;
}
.datetimepicker tbody tr > td.day.today,
.datetimepicker table tr td.active:active, .datetimepicker table tr td.active:hover:active, .datetimepicker table tr td.active.disabled:active, .datetimepicker table tr td.active.disabled:hover:active, .datetimepicker table tr td.active.active, .datetimepicker table tr td.active:hover.active, .datetimepicker table tr td.active.disabled.active, .datetimepicker table tr td.active.disabled:hover.active,
.datetimepicker table tr td span.active:active, .datetimepicker table tr td span.active:hover:active, .datetimepicker table tr td span.active.disabled:active, .datetimepicker table tr td span.active.disabled:hover:active, .datetimepicker table tr td span.active.active, .datetimepicker table tr td span.active:hover.active, .datetimepicker table tr td span.active.disabled.active, .datetimepicker table tr td span.active.disabled:hover.active {
  background: $color-theme !important;
  color: $color-white;
}


.datetimepicker table tr > td.today:hover,
.datetimepicker table tr > td.today:hover:hover,
.datetimepicker table tr > td.today.disabled:hover,
.datetimepicker table tr > td.today.disabled:hover:hover,
.datetimepicker table tr > td.today:active,
.datetimepicker table tr > td.today:hover:active,
.datetimepicker table tr > td.today.disabled:active,
.datetimepicker table tr > td.today.disabled:hover:active,
.datetimepicker table tr > td.today.active,
.datetimepicker table tr > td.today:hover.active,
.datetimepicker table tr > td.today.disabled.active,
.datetimepicker table tr > td.today.disabled:hover.active,
.datetimepicker table tr > td.today.disabled,
.datetimepicker table tr > td.today:hover.disabled,
.datetimepicker table tr > td.today.disabled.disabled,
.datetimepicker table tr > td.today.disabled:hover.disabled,
.datetimepicker table tr > td.today[disabled],
.datetimepicker table tr > td.today:hover[disabled],
.datetimepicker table tr > td.today.disabled[disabled],
.datetimepicker table tr > td.today.disabled:hover[disabled] {
  background: $color-theme !important;
  color: $color-white;
  opacity: .7;
}
.datetimepicker-dropdown-bottom-right:before {
  top: -7px;
  left: 6px;
}
.daterangepicker {
  padding: 10px;
  margin-top: 5px;
  .daterangepicker_input .input-mini {
    height: auto;
    padding: .65rem 1rem;
    padding-left: 2.2rem;
    line-height: 1.25;
  }
  td, th {
    width: 35px;
    height: 35px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    border: 0;
    vertical-align: middle;
    line-height: 0;
  }
  thead {
    .fa {
      &.fa-chevron-left, &.fa-chevron-right {
        display: inline-block;
        font: normal normal normal 16px/1 LineAwesome;
        font-size: inherit;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
      }
      &.fa-chevron-left:before {
        content: "";
      }
      &.fa-chevron-right:before {
        content: "";
      }
    }
    th {
      font-weight: 500;
      &.next, &.prev {
        font-size: 1.3rem;
      }
    }
  }
  tbody td {
    &.in-range {
      &.start-date.today {
        -webkit-border-top-left-radius: 0;
        -moz-border-top-left-radius: 0;
        -ms-border-top-left-radius: 0;
        -o-border-top-left-radius: 0;
        border-top-left-radius: 0;
        -webkit-border-bottom-left-radius: 0;
        -moz-border-bottom-left-radius: 0;
        -ms-border-bottom-left-radius: 0;
        -o-border-bottom-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.end-date.today {
        -webkit-border-top-right-radius: 0;
        -moz-border-top-right-radius: 0;
        -ms-border-top-right-radius: 0;
        -o-border-top-right-radius: 0;
        border-top-right-radius: 0;
        -webkit-border-bottom-right-radius: 0;
        -moz-border-bottom-right-radius: 0;
        -ms-border-bottom-right-radius: 0;
        -o-border-bottom-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &.today {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      position: relative;
      &.in-range {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        position: relative;
        &:before {
          content: '';
          display: inline-block;
          border: solid transparent;
          border-width: 0 0 7px 7px;
          border-bottom-color: #fff;
          border-top-color: rgba(0, 0, 0, 0.2);
          position: absolute;
          bottom: 4px;
          right: 4px;
        }
      }
      &:before {
        content: '';
        display: inline-block;
        border: solid transparent;
        border-width: 0 0 7px 7px;
        border-bottom-color: #fff;
        border-top-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
      }
    }
  }
  select {
    &.ampmselect, &.hourselect, &.minuteselect, &.monthselect, &.yearselect {
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      padding: .35rem .75rem;
      outline: 0 !important;
    }
  }
  .calendar-time {
    margin: 15px 0 5px 0;
    select {
      &.ampmselect, &.hourselect, &.minuteselect {
        width: 70px;
      }
    }
    i {
      left: 3px;
      top: 5px;
      display: inline-block;
      font: normal normal normal 16px/1 LineAwesome;
      font-size: 1.4rem;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
      &:before {
        content: "";
      }
    }
  }
  .ranges {
    padding: 10px;
    margin: 5px 5px 5px 10px;
    ul {
      width: 210px;
      li {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        background: 0 0;
        padding: 8px 12px;
        margin-bottom: 10px;
        font-weight: 400;
        border-color: transparent;
      }
    }
    .range_inputs {
      margin-top: 15px;
      .btn {
        padding: .6rem 1.6rem;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
        border-radius: 40px;
        margin-right: 6px;
      }
    }
  }
  .daterangepicker_input {
    .input-mini {
      height: auto;
      border: 1px solid #ebedf2;
      &.active, &:focus {
        border: 1px solid $color-theme;
      }
    }
    i {
      color: #b2b1c5;
    }
  }
  thead th {
    &.next:hover, &.prev:hover {
      background: #ebedf2;
    }
  }
  tbody td {
    &.available:hover, &:hover {
      background: #ebedf2;
    }
    &.off {
      color: #7b7e8a;
    }
    &.available {
      color: #575962;
    }
    &.today {
      background: $color-theme;
      color: #fff;
      &:before {
        border-bottom-color: #fff;
        border-top-color: rgba(0, 0, 0, 0.2);
      }
      &.in-range {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
      }
    }
    &.active, &.in-range, &.active:hover, &.in-range:hover {
      background: $color-theme;
      color: #fff;
    }
  }
  select {
    &.ampmselect, &.hourselect, &.minuteselect, &.monthselect, &.yearselect {
      border: 1px solid #ebedf2;
    }
    &.ampmselect {
      &.active, &:focus {
        border: 1px solid $color-theme;
      }
    }
    &.hourselect {
      &.active, &:focus {
        border: 1px solid $color-theme;
      }
    }
    &.minuteselect {
      &.active, &:focus {
        border: 1px solid $color-theme;
      }
    }
    &.monthselect {
      &.active, &:focus {
        border: 1px solid $color-theme;
      }
    }
    &.yearselect {
      &.active, &:focus {
        border: 1px solid $color-theme;
      }
    }
  }
  .calendar-time i {
    color: #b2b1c5;
  }
  .ranges li {
    border: 1px solid #ebedf2;
    color: #7b7e8a;
    &:hover {
      background: 0 0;
      color: #7b7e8a;
      background: #f9f9fd;
    }
    &.active {
      background: $color-theme;
      color: #fff;
      border: 1px solid $color-theme;
    }
  }
}