 /* ----------------------------------------------------------------------
    Page title
-------------------------------------------------------------------------*/

#page-title {
     clear: both;
     padding: 80px 0;
     background-color: $page-title-background;
     position: relative;
     .page-title {
         text-align: center;
         padding: 0;
         >h1 {
             font-family: $page-title-font;
             font-size: 44px;
             line-height: 56px;
             font-weight: 400;
             margin-bottom: 10px;
         }

         >span {
             font-weight: 400;
             font-size: 14px;
         }
     }

     .breadcrumb {
        text-align: center;
         margin-bottom: 0;
         padding: 0;
     }

     /* page header left*/
     &.page-title-left {
        .page-title {
            float: left;
            text-align: left;
            clear: both;
        }

        .breadcrumb {
            float: left;
            text-align: left;
        }
    }

     /* page header right*/
     &.page-title-right {
         .page-title {
             float: right;
             text-align: right;
             clear: both;
         }

         .breadcrumb {
             float: right;
             text-align: right;
         }
     }

     &.dark {
         .page-title {
             >h1 {
                 color: $dark;
             }

             >span {
                 color: $dark;
             }
         }

         .breadcrumb {
             ul {
                 li {
                     +li:before {
                         color: $dark;
                     }

                     a {
                         color: $dark;
                     }
                 }
             }
         }
     }

     &[data-parallax-image] {
        .page-title {
            >h1 {
                color: $color-white;
            }

            >span {
                color: $color-white;
            }
        }

        .breadcrumb {
            ul {
                li {
                    +li:before {
                        color: $color-white;
                    }

                    a {
                        color: $color-white;
                    }
                }
            }
        }
     }
 }


 @media (max-width: 991px) {

     #page-title {
         padding: 50px 0 !important;

         .page-title {
             >h1 {
                 font-size: 26px;
                 line-height: 34px;
             }
         }
     }
 }