

// Tooltips & Popover

/* .tooltip-inner {
    padding: 12px 20px;
} */
.tooltip.left .tooltip-arrow {
    right: 1px;
}

.tooltip-inner {
    white-space:pre-wrap;
}

// Popovers

.popover {
	border-radius: 0;
    border: none;
    background-color: #fcfcfc;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    color: #667;
    padding: 3px 3px 8px;
    > .arrow {
    	border-color: transparent !important;
    }
}
.popover-body {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400 !important;
    padding-top: 0;
    font-size: 14px;
    color: #667;
    position: relative;
    img {
        height: auto;
        position: relative;
    }
    > img,
	iframe {
		width: 100%;
        height: auto !important;
        margin-top: 10px;
	}
	iframe {
		background: url(../images/loading.gif) center center no-repeat;
	}
}   

.popover-header {
    padding: 18px 14px 0;
    margin: 0 0 10px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    background-color: inherit;
    border-bottom: none;
    border-radius: 0;
    &::after {
    	border-top: 1px solid #eee;
        content: "";
        display: block;
        height: 1px;
        margin: 12px 0 5px;
        width: 100%;
    }
}
