

/* ----------------------------------------------------------------
    SLIDER
-----------------------------------------------------------------*/

#slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 0px;
    background-color:$background-dark;
    z-index: 1;
}

#home {
    width: 100%;
    height: 100%;
    color: #FFF;
}

.container-fullscreen {
   padding-top: 0px;
    height: 100%;
    width: 100%;
    display: table;
    position: relative;

}

.fullscreen>.container,
.halfscreen>.container,
.fullscreen>.container-fluid,
.halfscreen>.container-fluid {
    height: 100%;
    z-index: 1;
}

.inline-YTPlayer {
	position: absolute !important;
	z-index: -1;
	top: 0 !important;
	left: 0 !important;
	right: 0;
	border: 0;
}

/* ----------------------------------------------------------------
    BOXED SLIDER
-----------------------------------------------------------------*/

.boxed-slider {
    height: 600px;
    width: 100%;
    .owl-item {
        height: 600px;
    }
    .owl-bg-img {
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.owl-item img {
    height: 100%;
}


/* ----------------------------------------------------------------
    INSPIRO SLIDER
-----------------------------------------------------------------*/

.inspiro-slider {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
    z-index: 2;
    background-color: $dark;
    &.slider-fullscreen,
    &.slider-fullwidth {
        height: 100%;
        width: 100%;
    }
    display: table;
    height: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    table-layout: fixed;
    width: 100%;
    z-index: 0;
    .owl-item {
        display: table;
        height: auto;
        overflow: hidden;
        padding: 0;
        position: relative;
        table-layout: fixed;
        width: 100%;
        z-index: 0;
    }
    .owl-stage-outer {
        height: 100%;
    }
    .slide {
        background-position: 50% 20%;
        background-repeat: repeat-y;
        background-size: cover;
        display: table-cell;
        height: 100%;
        position: relative;
        vertical-align: middle;
        width: 100%;
        z-index: 2;
    }
    .slide-captions {
        /* h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #fff;
            font-family: "Open Sans";
            font-weight: 600;
            letter-spacing: -0.5px;
        }*/
        h1 {
            font-size: 80px;
            font-weight: 700;
            line-height: 80px;
            margin-bottom: 30px;
            color: $color-white;
        }
        h2 {
            font-size: 60px;
            font-weight: 700;
            line-height: 68px;
            margin-bottom: 30px;
            color: $color-white;
        }
        h3,
        h4,
        h5,
        h6 {
            color: $color-white;
        }
        .strong {
            color: $color-white;
            display: block;
            font-family: "Open Sans";
            font-size: 12px !important;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 12px;
            margin-bottom: 20px;
            position: relative;
            text-transform: uppercase;
            &::after {
                border-top: 3px solid #fff;
                content: "";
                display: block;
                margin-bottom: 0;
                margin-top: 8px;
                width: 26px;
            }
        }
        >p {
            color: $color-white;
            font-size: 22px;
        font-weight: 300;
        line-height: 30px;
        margin-bottom: 40px;
        }
        .text-dark.strong::after,
        &.text-dark .strong::after {
            border-top-color: #111;
        }
        &.text-center .strong::after {
            margin-left: auto;
            margin-right: auto;
        }
        &.text-right .strong::after {
            margin-left: auto;
            margin-right: 0;
        }
        .slide-caption-hide {
            opacity: 0;
            visibility: hidden;
        }
        img {
            width: inherit !important;
        }
    }
    &.slider-loaded { 
        visibility: visible;
        opacity: 1;
    }
    
    .slide-link {
	width: 100%;
	height: 100%;
	top: 0;
    left: 0;
	position: absolute;
	z-index: 1;
}
}

//kenburn effect
.kenburns-bg {
    background-position: 50% 20%;
    background-repeat: repeat-y;
    background-size: cover;
    height: 100%;
    position: absolute;
    top: 0;
    vertical-align: middle;
    width: 100%;
    z-index: -1;
    transform: scale(1);
    transition: transform 0s cubic-bezier(0, 0, 0, 0) 0s;
    transition-delay: 1s;
}

.kenburns-bg.kenburns-bg-animate {
    transform: scale(1.11);
    transition: transform 10s cubic-bezier(0.2, 0, 0.7, 1) 0s;
    transition-delay: 1s;
}

.zoom-out {
    -webkit-animation-name: zoom-out;
    -moz-animation-name: zoom-out;
    -o-animation-name: zoom-out;
    animation-name: zoom-out;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

@-webkit-keyframes zoom-out {
    0% {
        -webkit-transform: scale(1.5);
        opacity: 0.1;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}

@-moz-keyframes zoom-out {
    0% {
        -moz-transform: scale(1.5);
        opacity: 0.1;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 1;
    }
}

@-o-keyframes zoom-out {
    0% {
        -o-transform: scale(1.5);
        opacity: 0.1;
    }
    100% {
        -o-transform: scale(1);
        opacity: 1;
    }
}

@keyframes zoom-out {
    0% {
        transform: scale(1.5);
        opacity: 0.1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}


/* ----------------------------------------------------------------
    Inspiro Slider - Responsive Classes
-----------------------------------------------------------------*/

@media (max-width:992px) {
    .inspiro-slider .slide-captions {
        h1,
        h2 {
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 14px;
        }
        >p {
            font-size: 18px;
            font-weight: 300;
            line-height: 26px;
            margin-bottom: 16px;
        }
        .btn {
            padding: 6px 14px !important;
            font-size: 10px !important;
            line-height: 1.5;
        }
    }
}


.inspiro-slider, .inspiro-slider .owl-stage-outer, .inspiro-slider .owl-stage, .inspiro-slider .slide {
    height: 0;
}


.swiper-slide .video-wrap,
.section .video-wrap,
.swiper-slide .yt-bg-player,
.section .yt-bg-player {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	top: 0;
	left: 0;
}

.yt-bg-player .inlinePlayButton,
.yt-bg-player .loading { display: none !important; }




