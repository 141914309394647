@import "_variables";
@import "_include";

/*.fa, .far, .fas {
  font-family: "Font Awesome 5 Pro";
}*/

/* Set the size of the div element that contains the map */
#map {
  height: 400px;  /* The height is 400 pixels */
  width: 100%;  /* The width is the width of the web page */
}

.hinterest{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
